
export const ERoleAuthorityCode = {
    create_ticket: 'create_ticket',

    employee_schedule: 'employee_schedule',

    update_ticket: 'update_ticket',

    delete_ticket: 'delete_ticket',

    view_all_ticket: 'view_all_ticket',

    project_management: 'project_management',

    update_all_ticket: 'update_all_ticket',

    expected_time_editor: 'expected_time_editor',

    copy_ticket: 'copy_ticket',

    soc_report: 'soc_report',

    service_portal_download: 'service_portal_download',

    unclosed_tickets_by_user: 'unclosed_tickets_by_user',

    unclosed_tickets_by_ip: 'unclosed_tickets_by_ip',

    dashboard_display: 'dashboard_display',

    my_todo_list_all: 'my_todo_list_all',
};
