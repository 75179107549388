import React, { Fragment } from 'react';
import MuiGrid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Link } from 'react-router-dom';
// import GodaBenefit from '../../../components/portal/parts/GodaBenefit';
import LanguageSelector from './parts/LanguageSelector';

import './AppFooter.css';

const AppFooter = (props) => {
    const { tl, tlns } = props;

    return (
      <Fragment>
        <MuiGrid className="app-footer" container spacing={1}>
          <MuiGrid item xs>
            <List className="app-footer-list">
              <ListItem>
                <Link to="/cloudgoda">Cloudgoda服務介紹</Link>
              </ListItem>
              <ListItem>
                <Link to="/CloudGodaQA">常見問與答</Link>
              </ListItem>
              <ListItem>
                <Link to="/terms-of-service">服務條款</Link>
              </ListItem>
              <ListItem>
                <Link to="/privacy-statement">隱私權政策</Link>
              </ListItem>
              <ListItem>
                <LanguageSelector></LanguageSelector>
              </ListItem>
            </List>
          </MuiGrid>
          <MuiGrid item xs>
            <List className="app-footer-list">
              <ListItem>
                <Link to="/contact-us">聯絡我們</Link>
              </ListItem>
              <ListItem>服務專線 : 02-2786-6286#7013</ListItem>
              <ListItem>
                © 2018-2024, 宏碁雲架構服務股份有限公司 (Acer e-Enabling Data Center, Inc.) All rights reserved.
              </ListItem>
              <ListItem>建議使用Chrome、Firefox瀏覽器瀏覽本網站的效果最佳。</ListItem>
            </List>
          </MuiGrid>
        </MuiGrid>
      </Fragment>
    );
};

export default AppFooter;
