import React, { useEffect,useState } from 'react';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from  'react-router-dom';
import Image from 'react-bootstrap/Image';
import { Col, Row } from 'react-bootstrap';
import { loginMenuAction } from '../../store/loginMenuSlice';
import { isNullOrUndefined } from "../../js/Utils/utils";
import classNames from 'classnames';
import {Input} from 'reactstrap';

const drawerWidth = 240;



const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const AppLoginSidebar = (props) => {
    const { tl, tlns} = props;
    const dispatch = useDispatch();
    const [autoscan, setAutoscan] = useState(JSON.parse(localStorage.getItem("autoscan")) || null);
    const [projectName, setProjectName] = useState(''); 
    const secondMenu = localStorage.getItem("secondMenu");
    const thirdMenuList = isNullOrUndefined(localStorage.getItem("thirdMenuList"))
      ? null
      : JSON.parse(localStorage.getItem("thirdMenuList"));
    
    const openSideBar = useSelector((state) => state.loginMenu.openSideBar);
    const thirdMenu = useSelector((state) => state.loginMenu.thirdMenu);
    
    useEffect(()=>{
        if(openSideBar === null){
            const sideBar = localStorage.getItem("openSideBar") === "true" ? true : false;
            dispatch(loginMenuAction.setSidebarByVal(sideBar));
        }
        setProjectName(localStorage.getItem("project_name"));
    },[dispatch, openSideBar]);

    useEffect(()=>{
        if(thirdMenu === null){
            dispatch(loginMenuAction.setSecondMenu(localStorage.getItem("thirdMenu")));
        }
    },[dispatch, thirdMenu]);

    const onClickHandler = (title) => {
        dispatch(loginMenuAction.setThirdMenu(title));
    };

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' },
            }}
            variant="persistent"
            anchor="left"
            open={openSideBar}
            className="AppLoginSidebar"
        >
            <DrawerHeader />
            <DrawerHeader className="Header">
                <p className="title"> {secondMenu === null || secondMenu === 'null' ? '' : tl(tlns, secondMenu)} </p>
            </DrawerHeader>
            <Row>
                {thirdMenuList !== null &&
                    thirdMenuList.map((menu, index) => (
                        <React.Fragment key={index}>
                            {menu.hasOwnProperty('category') && (
                                <>
                                    <Col xs={12} style={{ paddingLeft: '25px', paddingRight: '25px' }}>
                                        <p className="text-header">{tl(tlns, menu.category)}</p>
                                        <hr style={{ marginTop: '5px', marginBottom: '10px' }} />
                                    </Col>
                                </>
                            )}
                            <Col
                                className={
                                    menu.sublist
                                        ? 'menu-classification'
                                        : `menu-item ${thirdMenu === menu.title && 'selected'}`
                                }
                                xs={12}
                            >
                                <Link
                                    style={{ textDecoration: 'none' }}
                                    className="text-btn"
                                    to={menu.path}
                                    onClick={() => {
                                        onClickHandler(menu.title);
                                    }}
                                >
                                    {menu.sublist ? (
                                        <>
                                            <span>{tl(tlns, menu.title)}</span>
                                        </>
                                    ) : (
                                        <>
                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                <i
                                                    className={classNames(
                                                        'material-symbols-rounded',
                                                        'FILL0_wght200_GRAD0_opsz24',
                                                        {
                                                            selected: thirdMenu === menu.title,
                                                        }
                                                    )}
                                                    style={{
                                                        width: '45px',
                                                        height: '40px',
                                                        padding: '0px 0px 0px 10px',
                                                        alignContent: 'center',
                                                    }}
                                                >
                                                    {menu.icon}
                                                </i>
                                                <span>{tl(tlns, menu.title)}</span>
                                            </span>
                                        </>
                                    )}
                                </Link>
                            </Col>
                            {menu.hasOwnProperty('project') && (
                                <Col xs={12}>
                                    <p
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                            fontSize: '15px',
                                            fontWeight: 'bold',
                                            color: '#323232',
                                            marginTop: '15px',
                                            marginBottom: '15px',
                                        }}
                                    >
                                        <Input
                                            type="select"
                                            name="select"
                                            id="select"
                                            style={{ width: '198px', height: '30px', fontSize: '12px' }}
                                        >
                                            <option key={1} value={1}>
                                                {projectName}
                                            </option>
                                        </Input>
                                    </p>
                                </Col>
                            )}
                            {menu.sublist &&
                                menu.sublist.map((subItem, index) => (
                                    <Col
                                        className={`menu-item ${
                                            window.location.hash.substring(1) === subItem.title.replace(/\s+/g, '-') &&
                                            'selected'
                                        }`}
                                        xs={12}
                                    >
                                        <Link
                                            style={{ textDecoration: 'none' }}
                                            to={subItem.path}
                                            onClick={() => {
                                                onClickHandler(subItem.title);
                                            }}
                                        >
                                            <Image
                                                alt="Cloudgoda"
                                                className="icon"
                                                src={`/assets/images/ic_menu_${subItem.title.replace(
                                                    /\s+/g,
                                                    '-'
                                                )}_s.svg`}
                                                rounded
                                            />
                                            <span className="text-btn">{subItem.title}</span>
                                            <span className="text-btn" style={{ marginLeft: '10px' }}>
                                                {subItem.pattern[0] === '/resource-asset-configuration' &&
                                                    autoscan &&
                                                    (() => {
                                                        const data = autoscan.find((item) => {
                                                            if (
                                                                (item.id === 'region' &&
                                                                    subItem.title === 'Location') ||
                                                                (item.id === 'log-analytics-list' &&
                                                                    subItem.title === 'Log Analytics')
                                                            ) {
                                                                return true;
                                                            } else {
                                                                return (
                                                                    item.id ===
                                                                    subItem.title.replaceAll(' ', '-').toLowerCase()
                                                                );
                                                            }
                                                        });
                                                        return data ? data.count : null;
                                                    })()}
                                            </span>
                                        </Link>
                                    </Col>
                                ))}
                        </React.Fragment>
                    ))}
            </Row>
        </Drawer>
    );
}
export default AppLoginSidebar;
