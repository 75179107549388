import { produce } from "immer";

const initState = {
  tickets: [],
  tickets_by_assign_group_no: [],
  approvings: [],
  pick_ticket: null,
  errorcode: null,
  ticketChartData: null,
};

const todoListReducer = produce((state = initState, action) => {
  switch (action.type) {
      case 'MyToDoList_SUCCESS':
      case 'ProjectToDoList_SUCCESS':
          state.tickets = action.payload;
          return state;
      // console.log(action)
      // return {
      //     ...state,
      //     tickets: action.payload,
      //     errorcode: null
      // }
      case 'GetTodoListWithCompanyGroupAndAcct_SUCCESS':
          return {
              ...state,
              tickets: action.payload,
              errorcode: null,
          };
      case 'GetTodoListWithCompanyGroupAndAcctAll_SUCCESS':
          return {
              ...state,
              tickets: action.payload,
              errorcode: null,
          };

      case 'GetTicketByAssignGroupNo':
          return {
              ...state,
              tickets_by_assign_group_no: action.payload,
              errorcode: null,
          };
      case 'GetMyApprovingList_SUCCESS':
          return {
              ...state,
              approvings: action.payload,
              errorcode: null,
          };
      case 'ToDoList_Error':
          // console.log(action)
          return {
              ...state,
              tickets: null,
              errorcode: action.payload ? action.payload : 500,
          };
      case 'PickTicket':
          return {
              ...state,
              pick_ticket: action.payload,
          };
      case 'GetTicketChartData_SUCCESS':
          state.ticketChartData = action.payload;
          return state;
      // return {
      //   ...state,
      //   ticketChartData: action.payload,
      //   errorcode: null,
      // };
      default:
          return state;
  }
});

export default todoListReducer;
