export const getMenus = (auth) => {
    //登入後才可看到
    return [
        {
            title: 'overview',
            path: '/overview',
            pattern: ['/overview'],
            menu: [
                //CloudGoda介紹
                {
                    title: 'cloudgoda-intro',
                    path: '/cloudgoda',
                    pattern: ['/cloudgoda'],
                },
                //控制台主頁
                {
                    title: 'console-home',
                    path: '/console',
                    pattern: ['/console'],
                },
                //所有服務
                {
                    title: 'all-service',
                    path: '/overview/allservice',
                    pattern: ['/overview/allservice'],
                },
            ],
        },
        //雲端比價
        {
            title: 'pricingcomparison',
            path: '/pricecomparison',
            pattern: ['/pricecomparison'],
            menu: [
                //雲端服務商查詢
                {
                    title: 'pricingcomparison',
                    path: '/pricecomparison',
                    pattern: ['/pricecomparison'],
                },
                //網路延遲性檢測
                {
                    title: 'latency',
                    path: '/latency-testing',
                    pattern: ['/latency-testing'],
                },
            ],
        },
        //架構設計與部署
        {
            title: 'architecture-and-deployment',
            path: '/design-deploy',
            pattern: ['/design-deploy'],
            menu: [
                {
                    title: 'architecture-and-deployment',
                    path: '/design-deploy',
                    pattern: ['/design-deploy'],
                },
            ],
        },
        //雲端安全
        {
            title: 'cloud-security',
            path: '/cloud-security',
            pattern: ['/cloud-security'],
            menu: [
                //安全性態勢
                {
                    title: 'security-posture',
                    path: '/security-posture',
                    pattern: ['/security-posture'],
                    submenu: [
                        //安全性態勢儀表板
                        {
                            icon: 'dataset',
                            title: 'security-posture-dashboard',
                            path: '/cloud/security/securityPosture',
                            pattern: ['/cloud/security/securityPosture'],
                        },
                        //安全分數建議
                        {
                            icon: 'ballot',
                            title: 'security-score-recommendations',
                            path: '/cloud/security/securityScoreRecommendations',
                            pattern: ['/cloud/security/securityScoreRecommendations'],
                        },
                    ],
                },
                //法規合規性
                {
                    title: 'regulatory-compliance',
                    path: '/cloud/regulatory-compliance',
                    pattern: ['/cloud/regulatory-compliance'],
                    submenu: [
                        //法規合規性
                        {
                            icon: 'stars',
                            title: 'regulatory-compliance',
                            path: '/cloud/regulatoryCompliance',
                            pattern: ['/cloud/regulatoryCompliance'],
                        },
                    ],
                },
                //安全性建議
                {
                    title: 'security-recommendation',
                    path: '/cloud/security',
                    pattern: ['/cloud/security'],
                    submenu: [
                        //安全性建議
                        {
                            icon: 'stars',
                            title: 'security-recommendation',
                            path: '/cloud/security',
                            pattern: ['/cloud/security'],
                        },
                    ],
                },
                //Flowza
                {
                    title: 'flowza',
                    path: '/flowza',
                    pattern: ['/flowza'],
                    submenu: [
                        //Flowza介紹
                        {
                            title: 'flowza-introduction',
                            path: '/flowza',
                            show: false,
                            pattern: ['/flowza'],
                        },
                        //我的待完成清單(All)
                        {
                            icon: 'lists',
                            title: 'my-todo-list-all',
                            path: '/my-todo-list-all',
                            show: auth.my_todo_list_all,
                            pattern: ['/my-todo-list-all'],
                        },
                        //我的待完成清單
                        {
                            project: true,
                            icon: 'lists',
                            title: 'my-todo-list',
                            path: '/my-todo-list',
                            pattern: ['/my-todo-list'],
                        },
                        //歷史單查詢
                        {
                            icon: 'feature_search',
                            category: 'soc-ticket-list',
                            title: 'search-history-ticket',
                            path: '/search-history-ticket',
                            pattern: ['/search-history-ticket'],
                        },
                        //待結案使用者排行
                        {
                            icon: 'event_list',
                            title: 'pending-ticket-user-ranking',
                            path: '/pending-ticket-user-ranking',
                            show: auth.unclosed_list_by_user,
                            pattern: ['/pending-ticket-user-ranking'],
                        },
                        //待結案IP排行
                        {
                            icon: 'sort',
                            title: 'pending-ticket-ip-ranking',
                            path: '/pending-ticket-ip-ranking',
                            show: auth.unclosed_list_by_ip,
                            pattern: ['/pending-ticket-ip-ranking'],
                        },
                        //SOC報表
                        {
                            icon: 'view_timeline',
                            title: 'soc-report',
                            path: '/soc-report',
                            show: auth.soc_report,
                            pattern: ['/soc-report'],
                        },
                        //報表下載
                        {
                            icon: 'stars',
                            title: 'report-download',
                            path: '/report-download',
                            show: auth.service_portal,
                            pattern: ['/report-download'],
                        },
                        //SOC儀表板
                        {
                            icon: 'view_cozy',
                            title: 'soc-dashboard',
                            path: '/soc-dashboard',
                            show: auth.dashboard_display,
                            pattern: ['/soc-dashboard'],
                        },
                        //Incident
                        {
                            icon: 'fact_check',
                            category: 'my-flow',
                            title: 'incident',
                            path: '/incident',
                            pattern: ['/incident'],
                        },
                        //班表管理
                        {
                            icon: 'stars',
                            title: 'employee_schedule',
                            path: '/employee_schedule',
                            show: auth.employee_schedule,
                            pattern: ['/employee_schedule'],
                        },
                        //組態管理
                        {
                            icon: 'database',
                            title: 'configuration-management',
                            path: '/configuration-management',
                            show: false, //1130617先不上線
                            pattern: ['/configuration-management'],
                        },
                        //CMBD設定
                        {
                            icon: 'star',
                            category: 'cmdb',
                            title: 'cmdb-setting',
                            path: '/cmdb-setting',
                            // show: auth.cmdb_setting, 1130617先不上線
                            show: false,
                            pattern: ['/cmdb-setting'],
                        },
                        //公司管理
                        {
                            icon: 'schema',
                            category: 'system-setting',
                            title: 'company-management',
                            path: '/company-management',
                            // show: false,
                            show: auth.corp_management,
                            pattern: ['/company-management'],
                        },
                        //流程管理
                        {
                            icon: 'account_tree',
                            title: 'process-management',
                            path: '/project/process-management',
                            // show: false,
                            show: auth.process_management,
                            pattern: ['/project/process-management'],
                        },
                    ],
                },
                //Cloud SOC 規則管理  1130617先不上線
                // {
                //     title: 'cloud-soc-rule',
                //     path: '/cloud/cloud-soc-rule',
                //     pattern: ['/cloud/cloud-soc-rule'],
                //     submenu: [
                //         //SIEM 規則調整
                //         {
                //             title: 'siem-rule-adjustment',
                //             path: '/siem-rule-adjustment',
                //             pattern: ['/siem-rule-adjustment'],
                //         },
                //         //SOAR 條件啟⽤
                //         {
                //             title: 'enable-soar-condition',
                //             path: '/enable-soar-condition',
                //             pattern: ['/enable-soar-condition'],
                //         },
                //     ],
                // },
            ],
        },
        {
            title: 'smart-management',
            path: '/smart-management',
            pattern: ['/smart-management'],
            menu: [
                //成本分析
                {
                    title: 'cost-analysis',
                    path: '/smart-management',
                    pattern: ['/smart-management'],
                    submenu: [
                        //AZURE成本儀表板
                        {
                            icon: 'view_compact',
                            title: 'azure-cost-dashboard',
                            path: '/azure-cost-dashboard',
                            pattern: ['/azure-cost-dashboard'],
                        },
                        //成本分類
                        {
                            icon: 'clock_loader_60',
                            title: 'classification-cost',
                            path: '/classificationCost',
                            pattern: ['/classificationCost'],
                        },
                        //成本建議
                        {
                            icon: 'insert_chart',
                            title: 'cost-recommendations',
                            path: '/best/cost',
                            pattern: ['/best/cost'],
                        },
                    ],
                },
                //智能化建議
                {
                    title: 'smart-recommendations',
                    path: '/smart-management',
                    pattern: ['/smart-management'],
                    submenu: [
                        //可靠性建議
                        {
                            icon: 'stars',
                            title: 'reliability-recommendations',
                            path: '/best/reliabilityRecommendations',
                            pattern: ['/best/reliabilityRecommendations'],
                        },
                        //維運建議
                        {
                            icon: 'stars',
                            title: 'operational-recommendations',
                            path: '/best/operationalRecommendations',
                            pattern: ['/best/operationalRecommendations'],
                        },
                        //效能建議
                        {
                            icon: 'stars',
                            title: 'performance-recommendations',
                            path: '/best/performanceRecommendations',
                            pattern: ['/best/performanceRecommendations'],
                        },
                    ],
                },
            ],
        },
        {
            title: 'operation-excellent',
            path: '/operation-excellent',
            pattern: ['/operation-excellent'],
            menu: [
                //資源資產組態
                {
                    title: 'resource-asset-configuration',
                    path: '/azure-overview',
                    pattern: ['/azure-overview'],
                    submenu: [
                        //分類清單
                        {
                            title: 'classification-list',
                            path: '/classification-list',
                            pattern: ['/classification-list'],
                            sublist: [
                                {
                                    title: 'Subscription',
                                    path: '/resource-asset-configuration#Subscription',
                                    pattern: ['/resource-asset-configuration'],
                                },
                                {
                                    title: 'Location',
                                    path: '/resource-asset-configuration#Location',
                                    pattern: ['/resource-asset-configuration'],
                                },
                                {
                                    title: 'Virtual Network',
                                    path: '/resource-asset-configuration#Virtual-Network',
                                    pattern: ['/resource-asset-configuration'],
                                },
                                {
                                    title: 'Subnet',
                                    path: '/resource-asset-configuration#Subnet',
                                    pattern: ['/resource-asset-configuration'],
                                },
                                {
                                    title: 'Virtual Machine',
                                    path: '/resource-asset-configuration#Virtual-Machine',
                                    pattern: ['/resource-asset-configuration'],
                                },
                                {
                                    title: 'Public IP Address',
                                    path: '/resource-asset-configuration#Public-IP-Address',
                                    pattern: ['/resource-asset-configuration'],
                                },
                                {
                                    title: 'Disk',
                                    path: '/resource-asset-configuration#Disk',
                                    pattern: ['/resource-asset-configuration'],
                                },
                                {
                                    title: 'Network Security Group',
                                    path: '/resource-asset-configuration#Network-Security-Group',
                                    pattern: ['/resource-asset-configuration'],
                                },
                                {
                                    title: 'SQL Database',
                                    path: '/resource-asset-configuration#SQL-Database',
                                    pattern: ['/resource-asset-configuration'],
                                },
                                {
                                    title: 'Web App',
                                    path: '/resource-asset-configuration#Web-App',
                                    pattern: ['/resource-asset-configuration'],
                                },
                                {
                                    title: 'Storage Accounts',
                                    path: '/resource-asset-configuration#Storage-Accounts',
                                    pattern: ['/resource-asset-configuration'],
                                },
                                {
                                    title: 'Log Analytics',
                                    path: '/resource-asset-configuration#Log-Analytics',
                                    pattern: ['/resource-asset-configuration'],
                                },
                            ],
                        },
                    ],
                },
                //監控儀表板
                {
                    title: 'resource-dashboard',
                    path: '/resource-dashboard',
                    pattern: ['/resource-dashboard'],
                    submenu: [
                        //Azure 效能
                        {
                            title: 'azure-performance',
                            path: '/azure-performance',
                            pattern: ['/azure-performance'],
                            sublist: [
                                {
                                    title: 'Virtual Machine',
                                    path: '/performance-content#Virtual-Machine',
                                    pattern: ['/performance-content'],
                                },
                                {
                                    title: 'VM Scale Sets',
                                    path: '/performance-content#VM-Scale-Sets',
                                    pattern: ['/performance-content#'],
                                },
                                {
                                    title: 'Storage Accounts',
                                    path: '/performance-content#Storage-Accounts',
                                    pattern: ['/performance-content#'],
                                },
                                {
                                    title: 'Blob Storage',
                                    path: '/performance-content#Blob-Storage',
                                    pattern: ['/performance-content#'],
                                },
                                {
                                    title: 'Queue Storage',
                                    path: '/performance-content#Queue-Storage',
                                    pattern: ['/performance-content#'],
                                },
                                {
                                    title: 'File Storage',
                                    path: '/performance-content#File-Storage',
                                    pattern: ['/performance-content#'],
                                },
                                {
                                    title: 'Table Storage ',
                                    path: '/performance-content#Table-Storage-',
                                    pattern: ['/performance-content#'],
                                },
                                {
                                    title: 'Container Registry',
                                    path: '/performance-content#Container-Registry',
                                    pattern: ['/performance-content#'],
                                },
                                {
                                    title: 'Container Instance',
                                    path: '/performance-content#Container-Instance',
                                    pattern: ['/performance-content#'],
                                },
                                {
                                    title: 'Container Service',
                                    path: '/performance-content#Container-Service',
                                    pattern: ['/performance-content#'],
                                },

                                {
                                    title: 'Database Account',
                                    path: '/performance-content#Database-Account',
                                    pattern: ['/performance-content#'],
                                },
                                {
                                    title: 'App Service',
                                    path: '/performance-content#App-Service',
                                    pattern: ['/performance-content#'],
                                },
                            ],
                        },
                    ],
                },
                //服務狀態健康檢查
                {
                    title: 'service-health-check',
                    path: '/service-health-check',
                    pattern: ['/service-health-check'],
                    submenu: [
                        //作⽤中的服務問題事件
                        {
                            icon: 'empty_dashboard',
                            title: 'active-service-issue',
                            path: '/best/operationalExcellence/activeServiceIssue',
                            pattern: ['/best/operationalExcellence/activeServiceIssue'],
                        },
                        //作⽤中的計劃性維護事件
                        {
                            icon: 'dropdown',
                            title: 'active-planned-maintenance',
                            path: '/best/operationalExcellence/activePlannedMaintenance',
                            pattern: ['/best/operationalExcellence/activePlannedMaintenance'],
                        },
                        //Azure 狀態
                        {
                            icon: 'data_table',
                            title: 'azure-status',
                            path: '/best/operationalExcellence/azureStatus',
                            pattern: ['/best/operationalExcellence/azureStatus'],
                        },
                        //動作群組
                        {
                            icon: 'domain',
                            title: 'action-group',
                            path: '/best/operationalExcellence/actionGroup',
                            pattern: ['/best/operationalExcellence/actionGroup'],
                        },
                        //健康狀態警⽰
                        {
                            icon: 'verified_user',
                            title: 'health-alert',
                            path: '/best/operationalExcellence/healthAlert',
                            pattern: ['/best/operationalExcellence/healthAlert'],
                        },
                    ],
                },
            ],
        },
        //備份備援
        {
            title: 'cloudbackup',
            path: '/cloud-backup',
            pattern: ['/cloud-backup'],
            menu: [
                //⼯作概覽儀表板
                {
                    title: 'backup-dashboard',
                    path: '/job-overview',
                    pattern: ['/cloud-backup-dashboard'],
                },
                //備份基礎設施
                {
                    title: 'backup-infrastructure',
                    path: '/backup-infrastructure',
                    pattern: ['/backup-infrastructure'],
                },
                //備份流量
                {
                    title: 'backup-traffic',
                    path: '/backup-traffic',
                    pattern: ['/backup-traffic'],
                },
            ],
        },
    ];
};
